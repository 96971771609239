import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLayerGroup } from "@fortawesome/free-solid-svg-icons";

const ProductSubtypeSelector = ({ form, handleInputChange }) => (
  <div className="form-section">
    <h2>
      <FontAwesomeIcon icon={faLayerGroup} className="icon" />
      Product Subtype
    </h2>{" "}
    <select
      name="productSubtype"
      value={form.productSubtype}
      onChange={handleInputChange}
      className="dropdown"
    >
      <option value="">Select Product Subtype</option>
      <option value="Undefined">Undefined</option>
      <option value="PhoenixWithGuaranteedCoupons">
        Phoenix With Guaranteed Coupons
      </option>
      <option value="PhoenixDouble">Phoenix Double</option>
      <option value="Airbag">Airbag</option>
      <option value="AthenaApollon">Athena Apollon</option>
      <option value="AthenaApollonOxygen">Athena Apollon Oxygen</option>
      <option value="DailyAccrual">Daily Accrual</option>
      <option value="TriggerBarrierDiscountCertificate">
        Trigger Barrier Discount Certificate
      </option>
      <option value="TriggerBarrierReverseConvertible">
        Trigger Barrier Reverse Convertible
      </option>
      <option value="Phoenix">Phoenix</option>
      <option value="PhoenixPlus">Phoenix Plus</option>
      <option value="DiscountCertificate">Discount Certificate</option>
      <option value="BarrierDiscountCertificate">
        Barrier Discount Certificate
      </option>
      <option value="StandardReverseConvertible">
        Standard Reverse Convertible
      </option>
      <option value="BarrierReverseConvertible">
        Barrier Reverse Convertible
      </option>
      <option value="BarrierReverseWithConditionalCoupons">
        Barrier Reverse With Conditional Coupons
      </option>
      <option value="CallableReverseConvertible">
        Callable Reverse Convertible
      </option>
      <option value="CreditLinkedNote">Credit Linked Note</option>
      <option value="PhoenixCombo">Phoenix Combo</option>
      <option value="BonusCertificate">Bonus Certificate</option>
      <option value="CappedBonusCertificate">Capped Bonus Certificate</option>
      <option value="OutPerformer">Out Performer</option>
      <option value="CUOSingle">CUO Single</option>
      <option value="CUOMulti">CUO Multi</option>
      <option value="CallSpreadPutSpread">Call Spread Put Spread</option>
      <option value="SingleName">Single Name</option>
      <option value="DailyAthena">Daily Athena</option>
      <option value="Tranche">Tranche</option>
      <option value="CallPutVanilla">Call Put Vanilla</option>
      <option value="ShareAccumulator">Share Accumulator</option>
      <option value="ShareDecumulator">Share Decumulator</option>
      <option value="TwinWin">Twin Win</option>
      <option value="SuperTracker">Super Tracker</option>
      <option value="DCN">DCN</option>
      <option value="Sharkfin">Sharkfin</option>
      <option value="FixedRateNote">Fixed Rate Note</option>
      <option value="IssuerCallableFixedRateNote">
        Issuer Callable Fixed Rate Note
      </option>
      <option value="CappedFlooredFloatingRateNote">
        Capped Floored Floating Rate Note
      </option>
      <option value="IssuerCallableCappedFlooredFloatingRateNote">
        Issuer Callable Capped Floored Floating Rate Note
      </option>
      <option value="AutocallableCappedFlooredFloatingRateNote">
        Autocallable Capped Floored Floating Rate Note
      </option>
    </select>
  </div>
);

export default ProductSubtypeSelector;
