import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

const UnderlyingSearch = ({ form, setForm }) => {
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (form.underlyingSearch.trim().length > 0) {
        fetchData(form.underlyingSearch);
      } else {
        setResults([]);
      }
    }, 300);

    return () => clearTimeout(timer);
  }, [form.underlyingSearch]);

  const fetchData = async (query) => {
    setLoading(true);
    try {
      const response = await fetch(
        `http://localhost:3001/underlying?q=${encodeURIComponent(query)}`
      );
      const data = await response.json();
      setResults(data.data.map((item) => item.bbg_comp_ticker));
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleResultClick = (value) => {
    setForm((prev) => ({ ...prev, underlyingSearch: value }));
    setResults([]);
  };

  return (
    <div className="form-section">
      <h2>
        <FontAwesomeIcon icon={faSearch} className="icon" />
        Underlyings
      </h2>
      <div className="form-group">
        <input
          type="text"
          className="input-field"
          name="underlyingSearch"
          value={form.underlyingSearch}
          onChange={(e) =>
            setForm((prev) => ({ ...prev, underlyingSearch: e.target.value }))
          }
          placeholder="Search for Underlying"
        />
        {loading && <p>Loading...</p>}
      </div>
      {results.length > 0 && (
        <ul>
          {results.map((item, index) => (
            <li key={index} onClick={() => handleResultClick(item)}>
              {item}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default UnderlyingSearch;
