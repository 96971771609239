import React from "react";
import { Outlet } from "react-router-dom";
import Navbar from "../components/navbar/Navbar";

const MainLayout = () => {
  return (
    <div className="main-layout">
      <Navbar />
      <div className="main-content">
        <Outlet /> {/* Renders the protected pages like Dashboard */}
      </div>
    </div>
  );
};

export default MainLayout;
