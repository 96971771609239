import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  quoteList: [], // Stores all quotes
};

const quoteSlice = createSlice({
  name: "quotes",
  initialState,
  reducers: {
    addQuote: (state, action) => {
      // Add a new quote card
      state.quoteList.push(action.payload);
    },
    removeQuote: (state, action) => {
      // Remove a quote card by ID
      state.quoteList = state.quoteList.filter(
        (quote) => quote.id !== action.payload
      );
    },
    editQuote: (state, action) => {
      // Edit an existing quote card by ID
      const { id, updatedQuote } = action.payload;
      const index = state.quoteList.findIndex((quote) => quote.id === id);
      if (index !== -1) {
        state.quoteList[index] = { ...state.quoteList[index], ...updatedQuote };
      }
    },
  },
});

export const { addQuote, removeQuote, editQuote } = quoteSlice.actions;
export default quoteSlice.reducer;
