import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { removeQuote, editQuote } from "../../store/quoteSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBuilding,
  faMoneyBillWave,
  faCalendarAlt,
  faChartLine,
  faHandHoldingUsd,
  faDollarSign,
  faEuroSign,
  faPoundSign,
  faMoneyBill,
} from "@fortawesome/free-solid-svg-icons";
import { locationFlags } from "../../static/material";
import "./RightPanel.css";

const RightPanel = () => {
  const quotes = useSelector((state) => state.quotes.quoteList);
  const dispatch = useDispatch();

  const handleRemove = (id) => {
    dispatch(removeQuote(id));
  };

  const handleEdit = (id) => {
    const updatedQuote = prompt(
      "Enter updated quote details (e.g., Trade Size):"
    );
    if (updatedQuote) {
      dispatch(editQuote({ id, updatedQuote: { tradeSize: updatedQuote } }));
    }
  };

  // TODO: Move this to statics directory
  const currencyIcons = {
    USD: faDollarSign,
    EUR: faEuroSign,
    GBP: faPoundSign,
    CHF: faDollarSign, // Customize based on your data
  };

  return (
    <div className="right-panel">
      <div className="right-panel-header">
        <h3>Quotes Overview</h3>
      </div>

      {quotes.length === 0 ? (
        <p>No quotes available. Add a quote to see it here.</p>
      ) : (
        quotes.map((quote) => (
          <div className="quote-card" key={quote.id}>
            <div className="quote-card-header">
              <h4>
                {quote.productType || "Unknown Product"}{" "}
                <span className="quote-id">({quote.id})</span>
              </h4>
              <p>
                {locationFlags[quote.location] || ""}
                {quote.location || "Unknown Location"}
              </p>
            </div>
            <div className="quote-card-body">
              <div className="quote-grid">
                {/* 🔹 Price now appears at the top-left with bold & colored style */}
                <div className="quote-field price-field">
                  <FontAwesomeIcon icon={faMoneyBill} className="icon" />
                  <label>Price:</label>
                  <span className="price-value">{quote.price || "N/A"}%</span>
                </div>
                <div className="quote-field">
                  <FontAwesomeIcon icon={faBuilding} className="icon" />
                  <label>Issuer:</label>
                  <span>{quote.issuer || "N/A"}</span>
                </div>
                <div className="quote-field">
                  <FontAwesomeIcon icon={faMoneyBillWave} className="icon" />
                  <label>Trade Size:</label>
                  <span>{quote.tradeSize || "N/A"}</span>
                </div>
                <div className="quote-field">
                  <FontAwesomeIcon
                    icon={currencyIcons[quote.currency] || faDollarSign}
                    className="icon"
                  />
                  <label>Currency:</label>
                  <span>{quote.currency || "N/A"}</span>
                </div>
                <div className="quote-field">
                  <FontAwesomeIcon icon={faHandHoldingUsd} className="icon" />
                  <label>Coupon PA:</label>
                  <span>{quote.couponPa || "N/A"}</span>
                </div>
                <div className="quote-field">
                  <FontAwesomeIcon icon={faCalendarAlt} className="icon" />
                  <label>Maturity:</label>
                  <span>{quote.maturity || "N/A"}</span>
                </div>
                <div className="quote-field">
                  <FontAwesomeIcon icon={faChartLine} className="icon" />
                  <label>Barrier Level:</label>
                  <span>{quote.barrierLevel || "N/A"}%</span>
                </div>
                <div className="quote-field">
                  <FontAwesomeIcon icon={faChartLine} className="icon" />
                  <label>Strike Level:</label>
                  <span>{quote.strikeLevel || "N/A"}%</span>
                </div>
              </div>
            </div>
            <div className="quote-card-footer">
              <button onClick={() => handleEdit(quote.id)}>Edit</button>
              <button
                className="quote-card-remove"
                onClick={() => handleRemove(quote.id)}
              >
                Remove
              </button>
            </div>
          </div>
        ))
      )}
    </div>
  );
};

export default RightPanel;
