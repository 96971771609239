import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMoneyBillWave } from "@fortawesome/free-solid-svg-icons";

const TradeDetails = ({ form, handleInputChange }) => (
  <div className="form-section">
    <h2>
      <FontAwesomeIcon icon={faMoneyBillWave} className="icon" />
      Trade
    </h2>
    <div className="form-group">
      <label>Trade Size</label>
      <input
        type="number"
        name="tradeSize"
        placeholder="e.g., 70000"
        value={form.tradeSize}
        onChange={handleInputChange}
        className="input-field"
      />
    </div>
    <div className="form-group">
      <label>Currency</label>
      <select
        name="currency"
        value={form.currency}
        onChange={handleInputChange}
        className="dropdown"
      >
        <option value="">Select Currency</option>
        <option value="AUD">AUD</option>
        <option value="Any">Any</option>
        <option value="BRL">BRL</option>
        <option value="CAD">CAD</option>
        <option value="CHF">CHF</option>
        <option value="CLP">CLP</option>
        <option value="CNH">CNH</option>
        <option value="CNY">CNY</option>
        <option value="CZK">CZK</option>
        <option value="DKK">DKK</option>
        <option value="EUR">EUR</option>
        <option value="GBP">GBP</option>
        <option value="HKD">HKD</option>
        <option value="HUF">HUF</option>
        <option value="ILS">ILS</option>
        <option value="JPY">JPY</option>
        <option value="MXN">MXN</option>
        <option value="NOK">NOK</option>
        <option value="NZD">NZD</option>
        <option value="PEN">PEN</option>
        <option value="PLN">PLN</option>
        <option value="RON">RON</option>
        <option value="RUB">RUB</option>
        <option value="SEK">SEK</option>
        <option value="SGD">SGD</option>
        <option value="TRY">TRY</option>
        <option value="USD">USD</option>
        <option value="ZAR">ZAR</option>
      </select>
    </div>
    <div className="form-group">
      <label>Settlement Type</label>
      <div>
        <label>
          <input
            type="radio"
            name="settlementType"
            value="Cash"
            checked={form.settlementType === "Cash"}
            onChange={handleInputChange}
          />
          Cash
        </label>
        <label>
          <input
            type="radio"
            name="settlementType"
            value="Physical"
            checked={form.settlementType === "Physical"}
            onChange={handleInputChange}
          />
          Physical
        </label>
      </div>
    </div>

    <div className="form-group">
      <label>remuneration Mode</label>
      <div>
        <label>
          <input
            type="radio"
            name="remunerationMode"
            value="Reoffer"
            checked={form.remunerationMode === "Reoffer"}
            onChange={handleInputChange}
          />
          Reoffer
        </label>
        <label>
          <input
            type="radio"
            name="remunerationMode"
            value="Upfront"
            checked={form.remunerationMode === "Upfront"}
            onChange={handleInputChange}
          />
          Upfront
        </label>
        <label>
          <input
            type="radio"
            name="remunerationMode"
            value="Premium"
            checked={form.remunerationMode === "Premium"}
            onChange={handleInputChange}
          />
          Premium
        </label>
      </div>
    </div>

    <div className="form-group">
      <label>Denomination</label>
      <input
        type="number"
        name="denomination"
        value={form.denomination}
        onChange={handleInputChange}
        className="input-field"
        placeholder="denomination like: 1000"
      />
    </div>

    <div className="form-group">
      <label>Price Type</label>
      <span>Dirty</span> {/* Static text */}
    </div>
  </div>
);

export default TradeDetails;
