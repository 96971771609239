import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCubes } from "@fortawesome/free-solid-svg-icons";

const WrapperSelector = ({ form, handleInputChange }) => (
  <div className="form-section">
    <h2>
      <FontAwesomeIcon icon={faCubes} className="icon" />
      Wrapper
    </h2>
    <select
      name="wrapper"
      value={form.wrapper}
      onChange={handleInputChange}
      className="dropdown"
    >
      <option value="">Select wrapper</option>
      <option value="Undefined">Undefined</option>
      <option value="Note">Note </option>
      <option value="Option">Option</option>
      <option value="Swap">Swap</option>
      <option value="Warrant">Warrant</option>
    </select>
  </div>
);

export default WrapperSelector;
