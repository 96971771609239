import React from "react";
import { Outlet } from "react-router-dom";
import "./AuthLayout.css";
import Navbar from "../components/navbar/Navbar";

const AuthLayout = () => {
  return (
    <div>
      <Navbar />

      <div className="auth-layout">
        <div className="auth-content">
          <Outlet /> {/* Renders either Login or Register */}
        </div>
      </div>
    </div>
  );
};

export default AuthLayout;
