import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBuilding } from "@fortawesome/free-solid-svg-icons";

const IssuedBy = ({ form, handleInputChange }) => (
  <div className="form-section">
    <h2>
      <FontAwesomeIcon icon={faBuilding} className="icon" />
      Issued By
    </h2>
    <div className="form-group">
      <label>Issue By</label>
      <input
        type="text"
        name="issue-by"
        placeholder="Test"
        value={form.issuer}
        onChange={handleInputChange}
        className="input-field"
      />
    </div>
  </div>
);

export default IssuedBy;
