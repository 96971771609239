import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWrench } from "@fortawesome/free-solid-svg-icons";

const SolveForSection = ({ form, handleInputChange }) => (
  <div className="form-section">
    <h2>
      <FontAwesomeIcon icon={faWrench} className="icon" />
      Solve For
    </h2>
    <div className="form-group">
      <label>Solve Mode</label>
      <select
        name="solveMode"
        value={form.solveMode}
        onChange={handleInputChange}
        className="dropdown"
      >
        <option value="">Select Solving Mode</option>
        <option value="Airbag">Airbag</option>
        <option value="Bonus">Bonus</option>
        <option value="BonusEqualsCapLevel">Bonus Equals Cap Level</option>
        <option value="CapHighStrike">Cap High Strike</option>
        <option value="CapLevel">Cap Level</option>
        <option value="ClnFixedCoupon">CLN Fixed Coupon</option>
        <option value="ClnSpread">CLN Spread</option>
        <option value="ConditionalAndRecallCoupons">
          Conditional And Recall Coupons
        </option>
        <option value="CouponAndRecallCoupon">Coupon And Recall Coupon</option>
        <option value="CouponBarrier">Coupon Barrier</option>
        <option value="CouponValue">Coupon Value</option>
        <option value="FloorLowStrike">Floor Low Strike</option>
        <option value="GearingUpDownside">Gearing Up Downside</option>
        <option value="IssuePriceAmount">Issue Price Amount</option>
        <option value="IssuePricePercent">Issue Price Percent</option>
        <option value="KiAndCouponBarrier">KI And Coupon Barrier</option>
        <option value="KiBarrier">KI Barrier</option>
        <option value="KOLevel">KO Level</option>
        <option value="KnockOutPrice">Knock Out Price</option>
        <option value="Leverage">Leverage</option>
        <option value="LeverageCall">Leverage Call</option>
        <option value="LeverageLongCall">Leverage Long Call</option>
        <option value="LeverageLongCallAndShortCall">
          Leverage Long Call And Short Call
        </option>
        <option value="LeverageLongPut">Leverage Long Put</option>
        <option value="LeverageLongPutAndShortPut">
          Leverage Long Put And Short Put
        </option>
        <option value="LeveragePut">Leverage Put</option>
        <option value="LeverageShortCall">Leverage Short Call</option>
        <option value="LeverageShortPut">Leverage Short Put</option>
        <option value="OfferPrice">Offer Price</option>
        <option value="OptionPremium">Option Premium</option>
        <option value="Rebate">Rebate</option>
        <option value="RecallCoupon">Recall Coupon</option>
        <option value="RecallCouponAndCallStrike">
          Recall Coupon And Call Strike
        </option>
        <option value="RecallThreshold">Recall Threshold</option>
        <option value="RecallThresholdInGrid">Recall Threshold In Grid</option>
        <option value="Strike">Strike</option>
        <option value="StrikeAndCouponBarrier">
          Strike And Coupon Barrier
        </option>
        <option value="StrikeLongCall">Strike Long Call</option>
        <option value="StrikeLongPut">Strike Long Put</option>
        <option value="StrikeShortCall">Strike Short Call</option>
        <option value="StrikeShortPut">Strike Short Put</option>
        <option value="StrikePrice">Strike Price</option>
        <option value="SwapUpFront">Swap Up Front</option>
        <option value="TriggerCoupon">Trigger Coupon</option>
        <option value="TriggerLevel">Trigger Level</option>
        <option value="UpfrontFee">Upfront Fee</option>
        <option value="LeverageUpside">Leverage Upside</option>
        <option value="Spread">Spread</option>
        <option value="Cap">Cap</option>
        <option value="Floor">Floor</option>
        <option value="Target">Target</option>
        <option value="FixedAmount">Fixed Amount</option>
        <option value="FixedCoupon">Fixed Coupon</option>
      </select>
    </div>
    <div className="form-group">
      <label>Distribution Fee</label>
      <input
        type="text"
        name="distributionFee"
        placeholder="e.g., 1%"
        value={form.distributionFee}
        onChange={handleInputChange}
        className="input-field"
      />
    </div>
  </div>
);

export default SolveForSection;
