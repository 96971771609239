import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";

const DatesAndCoupon = ({ form, handleInputChange }) => (
  <div className="form-section">
    <h2>
      <FontAwesomeIcon icon={faCalendarAlt} className="icon" />
      Dates & Coupon
    </h2>
    <div className="form-group">
      <label>Maturity</label>
      <select
        name="maturity"
        value={form.maturity}
        onChange={handleInputChange}
        className="dropdown"
      >
        <option value="">Select Maturity</option>
        <option value="3m">3 Months</option>
        <option value="6m">6 Months</option>
        <option value="9m">9 Months</option>
        <option value="1y">1 Year</option>
        <option value="15m">1 Year 3 Months</option>
        <option value="18m">1 Year 6 Months</option>
        <option value="21m">1 Year 9 Months</option>
        <option value="2y">2 Years</option>
        <option value="27m">2 Years 3 Months</option>
        <option value="30m">2 Years 6 Months</option>
        <option value="33m">2 Years 9 Months</option>
        <option value="3y">3 Years</option>
        <option value="39m">3 Years 3 Months</option>
        <option value="42m">3 Years 6 Months</option>
        <option value="45m">3 Years 9 Months</option>
        <option value="4y">4 Years</option>
        <option value="51m">4 Years 3 Months</option>
        <option value="54m">4 Years 6 Months</option>
        <option value="57m">4 Years 9 Months</option>
        <option value="5y">5 Years</option>
        <option value="63m">5 Years 3 Months</option>
        <option value="66m">5 Years 6 Months</option>
        <option value="69m">5 Years 9 Months</option>
        <option value="6y">6 Years</option>
        <option value="75m">6 Years 3 Months</option>
        <option value="78m">6 Years 6 Months</option>
        <option value="81m">6 Years 9 Months</option>
        <option value="7y">7 Years</option>
        <option value="87m">7 Years 3 Months</option>
        <option value="90m">7 Years 6 Months</option>
        <option value="93m">7 Years 9 Months</option>
        <option value="8y">8 Years</option>
        <option value="99m">8 Years 3 Months</option>
        <option value="102m">8 Years 6 Months</option>
        <option value="105m">8 Years 9 Months</option>
        <option value="9y">9 Years</option>
        <option value="111m">9 Years 3 Months</option>
        <option value="114m">9 Years 6 Months</option>
        <option value="117m">9 Years 9 Months</option>
        <option value="10y">10 Years</option>
      </select>
    </div>
    <div className="form-group">
      <label>Coupon Frequency</label>
      <select
        name="couponFrequency"
        value={form.couponFrequency}
        onChange={handleInputChange}
        className="dropdown"
      >
        <option value="">Select Coupon Frequency</option>
        <option value="InFine">InFine</option>
        <option value="OnePerYear">OnePerYear</option>
        <option value="TwoPerYear">TwoPerYear</option>

        <option value="ThreePerYear">ThreePerYear</option>
        <option value="FourPerYear">FourPerYear</option>
        <option value="OnePerMonth">OnePerMonth</option>
        <option value="SixPerYear">SixPerYear</option>
        <option value="TwelvePerYear">TwelvePerYear</option>
        <option value="None">None</option>
      </select>
    </div>

    <div className="form-group">
      <label>Redemption Date</label>
      <input
        type="datetime-local"
        name="redemptionDate"
        value={form.redemptionDate}
        onChange={handleInputChange}
        className="form-control"
      />
    </div>
  </div>
);

export default DatesAndCoupon;
