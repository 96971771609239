import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import { locationFlags } from "../static/material";

const LocationSelector = ({ form, handleInputChange }) => (
  <div className="form-section">
    <h2>
      <FontAwesomeIcon icon={faMapMarkerAlt} className="icon" />
      Location
    </h2>
    <select
      name="location"
      value={form.location}
      onChange={handleInputChange}
      className="dropdown"
    >
      <option value="">Select Location</option>
      <option value="Dubai">{locationFlags.Dubai} Dubai</option>
      <option value="Switzerland">
        {locationFlags.Switzerland} Switzerland
      </option>
      <option value="Germany">{locationFlags.Germany} Germany</option>
    </select>
  </div>
);

export default LocationSelector;
