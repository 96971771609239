import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBoxOpen } from "@fortawesome/free-solid-svg-icons";

const ProductTypeSelector = ({ form, handleInputChange }) => (
  <div className="form-section">
    <h2>
      <FontAwesomeIcon icon={faBoxOpen} className="icon" />
      Product Type
    </h2>
    <select
      name="productType"
      value={form.productType}
      onChange={handleInputChange}
      className="dropdown"
    >
      <option value="">Select Product Type</option>
      <option value="Undefined">Undefined</option>
      <option value="Autocall">Autocall</option>
      <option value="ReverseConvertible">Reverse Convertible</option>
      <option value="CreditLinkedNote">Credit Linked Note</option>
      <option value="Participation">Participation</option>
      <option value="Accumulator">Accumulator</option>
      <option value="FxDualCurrency">FX Dual Currency</option>
      <option value="Sharkfin">Sharkfin</option>
      <option value="FixedRateNote">Fixed Rate Note</option>
      <option value="FloatingRateNote">Floating Rate Note</option>
    </select>
  </div>
);

export default ProductTypeSelector;
