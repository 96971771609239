import React, { useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBoxOpen, faSpinner } from "@fortawesome/free-solid-svg-icons";
// import Modal from "./components/Modal";
import LocationSelector from "../components/LocationSelector";
import ProductTypeSelector from "../components/ProductTypeSelector";
import ProductSubtypeSelector from "../components/ProductSubtypeSelector";
import WrapperSelector from "../components/WrapperSelector";
import UnderlyingSearch from "../components/UnderlyingSearch";
import DatesAndCoupon from "../components/DatesAndCoupon";
import TradeDetails from "../components/TradeDetails";
import SolveForSection from "../components/SolveForSection";
import axios from "axios";
import { toast } from "react-toastify";
import FinalRedemption from "../components/FinalRedemption";
import IssuedBy from "../components/IssuedBy";
import RightPanel from "../components/right-panel/RightPanel";
import { locationFlags } from "../static/material";
import { useDispatch } from "react-redux";
import { addQuote } from "../store/quoteSlice";

const Dashboard = () => {
  const dispatch = useDispatch();

  const [form, setForm] = useState({
    productType: "",
    productSubtype: "",
    wrapper: "",
    solvingMode: "",
    underlyingSearch: "",
    maturity: "",
    couponFrequency: "",
    redemptionDate: "",
    barrierType: "",
    barrierLevel: "",
    strikeLevel: "",
    tradeSize: "",
    currency: "",
    settlementType: "",
    remunerationMode: "",
    denomination: "",
    solveMode: "",
    distributionFee: "",
    issuer: "",
    location: "",
  });

  const [loading, setLoading] = useState(false);
  const [quoteData, setQuoteData] = useState(null);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setForm((prevForm) => ({ ...prevForm, [name]: value }));
  };

  const handleTheQuote = () => {
    setLoading(true);
    const postData = {
      productType: form.productType,
      productSubtype: form.productSubtype,
      wrapper: form.wrapper,
      solvingMode: form.solveMode,
      currency: form.currency,
      remunerationMode: form.remunerationMode,
      underlying: form.underlyingSearch,
      maturityValue: form.maturity,
      couponFrequency: form.couponFrequency,
      kiBarrierType: form.barrierType,
      strike: form.strikeLevel,
      kiBarrier: form.barrierLevel,
      notionalAmount: form.tradeSize,
      settlementType: form.settlementType,
      denomination: form.denomination,
    };

    axios
      .post("http://localhost:3001/quotes", postData, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        const quoteid = response.data.QuoteId;
        toast.success(`QuoteId: ${quoteid}`);

        axios
          .post(
            "http://localhost:3001/getQuote",
            { quoteid },
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          )
          .then((response) => {
            // const quoteData = response.data;
            // setModalContent(JSON.stringify(quoteData, null, 2));
            // setModalOpen(true);
            console.log("reponse. data", response.data);
            console.log(
              "datasss",
              response.data.Data.Quote.CouponFrequency,
              response.data.Data.Quote.NotionalAmount,
              // response.data.Data.Quote,
              response.data.Data.SolveResult
            );
            setQuoteData({ quoteData: response.data, form });
            dispatch(
              addQuote({
                id: quoteid,
                productType: form.productType,
                tradeSize: form.tradeSize,
                couponPa: response.data.Data.Quote.CouponFrequency,
                barrierLevel: form.barrierLevel,
                strikeLevel: response.data.Data.Quote.NotionalAmount,
                issuer: form.issuer,
                maturity: form.maturity,
                location: form.location,
                currency: response.data.Data.Quote.Currency,
                price: response.data.Data.SolveResult,
              })
            );
            setLoading(false);
          })
          .catch(() => {
            setLoading(false);
            toast.error("Error fetching the quote");
          });
      })
      .catch((e) => {
        setLoading(false);
        toast.info(`Error submitting quote:${e.message}`);
      });
  };

  return (
    <div className="app">
      <div className="main-container">
        <div className="container">
          <h1 className="header">
            <FontAwesomeIcon icon={faBoxOpen} className="icon" />
            Quote for {locationFlags[form.location] || "-"}{" "}
            {form.location || ""}
          </h1>

          <LocationSelector form={form} handleInputChange={handleInputChange} />
          <ProductTypeSelector
            form={form}
            handleInputChange={handleInputChange}
          />
          <ProductSubtypeSelector
            form={form}
            handleInputChange={handleInputChange}
          />
          <WrapperSelector form={form} handleInputChange={handleInputChange} />
          <UnderlyingSearch form={form} setForm={setForm} />
          <DatesAndCoupon form={form} handleInputChange={handleInputChange} />
          <FinalRedemption form={form} handleInputChange={handleInputChange} />
          <TradeDetails form={form} handleInputChange={handleInputChange} />
          <SolveForSection form={form} handleInputChange={handleInputChange} />
          <IssuedBy form={form} handleInputChange={handleInputChange} />

          {loading ? (
            <div className="loading-container">
              <FontAwesomeIcon icon={faSpinner} className="spinner" />
            </div>
          ) : (
            <div className="button-container">
              <button onClick={handleTheQuote} className="quote-button">
                Get a Quote
              </button>
            </div>
          )}
        </div>
        <RightPanel quoteData={quoteData} />
      </div>
    </div>
  );
};

export default Dashboard;
