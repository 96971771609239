import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowAltCircleUp } from "@fortawesome/free-solid-svg-icons";

const FinalRedemption = ({ form, handleInputChange }) => (
  <div className="form-section">
    <h2>
      <FontAwesomeIcon icon={faArrowAltCircleUp} className="icon" />
      Early Redemption
    </h2>
    <div className="form-group">
      <label>Barrier Type</label>
      <select
        name="barrierType"
        value={form.barrierType}
        onChange={handleInputChange}
        className="dropdown"
      >
        <option value="">Select Barrier Type</option>
        <option value="None">None</option>
        <option value="European">European</option>
        <option value="UsClose">UsClose</option>
        <option value="UsIntraday">UsIntraday</option>
      </select>
    </div>

    <div className="form-group">
      <label>Barrier Level</label>
      <input
        type="number"
        name="barrierLevel"
        placeholder="e.g., 70%"
        value={form.barrierLevel}
        onChange={handleInputChange}
        className="input-field"
      />
    </div>

    <div className="form-group">
      <label>Strike Level</label>
      <input
        type="number"
        name="strikeLevel"
        placeholder="e.g., 70%"
        value={form.strikeLevel}
        onChange={handleInputChange}
        className="input-field"
      />
    </div>
  </div>
);

export default FinalRedemption;
